<template>
  <div>
    <!-- Login Inputs -->
    <div v-if="showLoginInputs">
      <div class="flex">
        <div class="w-9/12">
          <vs-input 
            :label="$t('phoneNumber') || 'رقم الجوال'"
            :placeholder="$t('phoneNumberPlaceholder')"
            dir="ltr" 
            v-model="phoneNumberWithoutKey"
            class="w-full" 
            autocomplete="off" 
            :danger="validation.hasError('phoneNumberWithoutKey')" 
            :danger-text="validation.firstError('phoneNumberWithoutKey')"
          />              
        </div>
        <div class="w-3/12">
          <v-select
              :clearable="false"
              class="v-select-background w-full mt-6"
              v-model="phoneNumberKey"
              :options="['966']"
              dir="rtl"
          />
        </div>
      </div>

      <div class="my-5">
        <span class="my-3 text-lg">Send VC via:</span>
        <ul class="mt-2">
          <li>
            <vs-radio v-model="vc_channel" vs-name="vc_channel" vs-value="whatsapp">Whatsapp</vs-radio>
          </li>
          <li>
            <vs-radio v-model="vc_channel" vs-name="vc_channel" vs-value="sms">SMS</vs-radio>
          </li>
        </ul>
      </div>

      <div class="flex flex-wrap justify-between mb-3">
        <vs-button :disabled="!validateForm" @click="loginClicked">{{$t('buttons.sendCode')}}</vs-button>
        <vs-button v-if="!isProduction" @click="changePhoneNumber">(:</vs-button>
      </div>
    </div>

    <div v-if="showVerificationInputs">
      <div>
        <p>{{$t('messages.sentCode')}} <span>{{phoneNumber}}</span></p>
        <p>{{$t('messages.enterCode')}}</p>
      </div>
      <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="verification-code"
          icon-no-border
          icon="icon icon-phone"
          icon-pack="feather"
          :label-placeholder="$t('verificationCode') || 'رمز التحقق'"
          v-model="verificationCode"
          class="w-full py-3"/>
      <span class="text-danger text-sm">{{ errors.first('verification-code') }}</span>    
      

      <vs-input
          v-if="isSuperUser"
          v-validate="'required'"
          data-vv-validate-on="blur"
          type="password"
          name="password"
          icon-no-border
          icon="icon icon-lock"
          icon-pack="feather"
          :label-placeholder="$t('password') || 'كلمة المرور'"
          v-model="password"
          class="w-full py-3"/>
      

      <div v-if="isSuperUser" class="w-full py-3 mb-4">
          <label class="v-select-label">{{$t('chooseVenue') || 'اختر الصالة'}}</label>
          <v-select 
              v-model="venue_id" 
              @search="requestVenues"
              :options="venues"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" 
              label="name"
          >
            <template v-slot:option="option">
              {{ option.arabic_name }} - {{ option.name }}
            </template>
          </v-select>
      </div>

      <div class="flex flex-wrap justify-between py-3">
        <vs-button :disabled="!validateForm" @click="verifyClicked">{{$t('buttons.verify')}}</vs-button>
        <vs-button color="primary" type="border" icon="arrow_back_ios" icon-after style="direction: rtl" @click="changePhoneNumberClicked">{{$t('buttons.changeNumber')}}</vs-button>
      </div>
    </div>
  </div>
  
</template>

<script>

import axios from '@/axios'
import VSelect from 'vue-select'

import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

export default {
  data () {
    return {
      phoneNumberWithoutKey: '',
      phoneNumberKey: '966',
      showLoginInputs: true,
      showVerificationInputs: false,
      verificationCode: '',
      password: '',
      venues: [],
      venue_id: '',
      isSuperUser: false,
      vc_channel: 'whatsapp',
    }
  },
  validators: {
    'phoneNumberWithoutKey': function (value) {
        let numCount = Validator.value(value).regex('^5[0-9]{8}$', this.$t('venueInformationForm.phoneNumberNumbersCount'));
        let format = Validator.value(value).regex('^5[0-9]{0,50}$', this.$t('venueInformationForm.phoneNumberFormat'))
        if(format._messages.length > 0) {
          return format;
        } else {
          return numCount;
        }
    },
        
  },
  components: {
    VSelect
  },
  computed: {
    validateForm () {
      return true
    },
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    isProduction(){ return process.env.NODE_ENV === "production" },
    isDevelopment(){ return process.env.NODE_ENV === "development" },
    phoneNumber() {
      return this.phoneNumberKey.concat(this.phoneNumberWithoutKey);
    }
  },
  created() {
    if(this.isDevelopment) this.password = '0000000'
    if (!this.isProduction) {
      this.phoneNumberWithoutKey = '530606369',
      
      axios.get(`/agentVenues?q=1`)
      .then((response) => {
        this.venues = response.data.data.venues
        this.venue_id = response.data.data.venues[0]
      })
      .catch((error) => { reject(error) })
    }
    this.$store.dispatch('loader/loaderOff')
  },
  methods: {
    emitErrorMessage(error){
      this.$emit('error', error.response.data.error_message)
    },

    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },

    loginClicked () {
      this.$validate()
      if(this.validation.countErrors() == 0) {
        const params = new URLSearchParams();
        params.append('phone_number', this.phoneNumber);
        params.append('vc_channel', this.vc_channel);
        this.$store.dispatch('loader/loaderOn')
        this.$store.dispatch('auth/loginBySMS', params)
        .then((response) => {
          this.$store.dispatch('loader/loaderOff')
          this.isSuperUser = response.data.is_q_super_admin;
          this.showLoginInputs = false;
          this.showVerificationInputs = true;
          if (response.data.vc){
            this.verificationCode = response.data.vc 
          }
          
        })
        .catch((error) => {
          this.$store.dispatch('loader/loaderOff')
          this.emitErrorMessage(error)
        })
        return;

      }
    },

    changePhoneNumberClicked(){
      this.showVerificationInputs = false
      this.showLoginInputs = true
    },

    verifyClicked () {

      // Loading
      // this.$vs.loading()
      this.$store.dispatch('loader/loaderOn')
      const params = new URLSearchParams();
      params.append('phone_number', this.phoneNumber);
      params.append('code', this.verificationCode);
      if(this.isSuperUser) {
        params.append('is_q_super_admin_login', this.isSuperUser);
        params.append('password', this.password);
        if(this.venue_id) params.append('venue_id', this.venue_id.id);
        else{
          this.emitErrorMessage({response: { data: { error_message: 'الرجاء اختيار القاعة'}}})
          this.$store.dispatch('loader/loaderOff')
          return false;
        }
      }

      this.$store.dispatch('auth/verifyCode', params)
      .then((response) => {
        if(response.data.venue.onboarding_status == 'incompleted')
          this.$router.push(`/${response.data.venue.id}/on-boarding`);
        else {
          if(localStorage.getItem('redirectToUrl')) {
            /*
              the following code redirects the user dynamically as the original url was requested and the user was asked to login first

              algo:
                1) we save the original url in localStorage under redirectToUrl key
                2) we reach this function where we separate the path from the query string
                3) we redirect the user to the original url (if the redirectToUrl was set) with the path and queries

              strRoutePath: is where the path was requested. ex, #/1/calendar
              the slice(1) is to remove the hashtag from the string since it is always here and we don't want is

              arrQueries: array of the request queries. ex, {booking_id: 87, something_else: 'lucky'}

            */
           
            let url = localStorage.getItem('redirectToUrl');
            localStorage.removeItem('redirectToUrl')

            let strQueries = url.split('?')[1];
            let strRoutePath = new URL(url.split('?')[0]).hash.slice(1);
            
            let arrQueries = strQueries ? strQueries.split(/\=|\&/) : [];
            let params = {};
            for (let index = 0; index < arrQueries.length; index += 2) {
              params[[arrQueries[index]]] = arrQueries[index+1];
            }
            
            this.$router.push({ path: strRoutePath, query: params })
            
          } else {
            this.$router.push('/');
          }

        }
        this.$store.dispatch('loader/loaderOff')
      })
      .catch((error) => {
        this.$store.dispatch('loader/loaderOff')
        this.emitErrorMessage(error)
      })
      return;



      axios.post('/checkCodeToLogin', params)
      .then((response) => {
        this.$vs.loading.close()
        this.$router.push('/');
        var token = response.data.token
        console.log("=======", token);
      }, (error) => {
        this.$vs.loading.close()
        this.emitErrorMessage(error)
        console.log("the error is", error.response.data.error_message);
      });
    },
    requestVenues(value) { //this value contains the pressed letter only, so I had to concat the value manually
      if(value) {
        axios.get(`/agentVenues?q=${value}`)
          .then((response) => {
            this.venues = response.data.data.venues
          })
          .catch((error) => { reject(error) })
      }
    },
    changePhoneNumber() {
      let phones = ['530606369','560039498', '559876407'];
      let phoneIndex = phones.indexOf(this.phoneNumberWithoutKey) + 1;
      this.phoneNumberWithoutKey = phones[phoneIndex == phones.length ? 0 : phoneIndex]; // I tried to use (%) and did't work on my machine xD
    }
  }
}

</script>

