<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <!-- todo: the alert should be moved to a better place to be reusable -->
      <vs-alert :active.sync="errorAlertIsShown" closable icon-pack="feather" color="danger" close-icon="icon-x">
            {{errorMessage}}
      </vs-alert>

      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <div class="grid grid-rows-7">
                <img src="@/assets/images/logo/logo-orange.png" class="w-1/2 row-span-4 justify-self-center"   alt="login" />
                <div class="text-primary row-span-2 justify-self-center mt-10 text-9xl" style="font-size: 50px">{{$t('appName')}}</div>
                <div class="text-primary row-span-1 justify-self-center text-9xl" style="font-size: 20px">{{$t('appSlug')}}</div>
                <!-- <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto"> -->
              </div>
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="px-8 pt-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">{{$t("login") || 'تسجيل الدخول'}}</h4>
                  <p>{{$t("welcomeLogin") || 'أهلا بك مجددا، قم بإدخال رقمك للدخول'}}</p>
                </div>

                <vs-tabs>
                  <vs-tab label="">
                    <login @error="showErrorAlert"></login>
                  </vs-tab>
                </vs-tabs>


              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
import Login from './SMSLogin'
// import SMSLogin from './SMSLogin.vue'

export default {
  data(){
    return {
      errorMessage: '',
      errorAlertIsShown: false
    }
  },

  components: {
    Login
  },
  methods: {
    showErrorAlert(errorMessage){
      this.errorAlertIsShown = true;
      this.errorMessage = errorMessage;
    }
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
